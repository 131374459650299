import React from 'react';
import { createContext, useContext, useEffect } from 'react';
import useWebSocket, { ReadyState } from "react-use-websocket";
import {WS_URL} from '../../util/Constants';

const WSContext = createContext({
    sendJsonMessage: undefined,
    lastJsonMessage: undefined,
    connectionStatus: undefined,
});

const WSContextProvider = ({ children }) => {
    console.log("here");
    
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(WS_URL, {
         share: true ,
        // shouldReconnect: (closeEvent) => true,  // Reconnect on close
        //reconnectAttempts: 10,                 // Set number of attempts
        //reconnectInterval: 3000,               // Set interval between attempts (in ms)
        onError: (event) => {
            console.error("WebSocket error:", event);
        },
        onClose: (event) => {
            console.log("WebSocket closed:", event);
        }

        
        });

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    console.log("Connection Status:", connectionStatus);

    useEffect(() => {
        if (lastJsonMessage !== null) {
            console.log("Last Message:", lastJsonMessage);
        }
    }, [lastJsonMessage]);
    

    // useEffect(() => {
    //     if (readyState === ReadyState.OPEN) {
    //         sendJsonMessage({
    //             type: WS_TYPES.INIT,
    //             data: {
    //                 message: "node connected."
    //             }
    //         });
    //     }
    // }, [readyState]);

    return (
        <WSContext.Provider value={{
            sendJsonMessage,
            lastJsonMessage,
            connectionStatus
        }}>
            {children}
        </WSContext.Provider>
    )
}

export const useWSContext = () => {
    const context = useContext(WSContext);
    return {
        sendJsonMessage: context.sendJsonMessage,
        lastJsonMessage: context.lastJsonMessage,
        connectionStatus: context.connectionStatus
    }
}

export default WSContextProvider;